import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default class Cursos extends Component {
  render() {
    return (
      <Layout className="uk-background-secondary">
        <SEO
          title="Cursos"
          keywords={[
            'escuela de aviacion',
            'piloto privado',
            'airline pilot academy',
            'aviacion',
            'cochabamba',
            'bolivia',
          ]}
        />
        <div className="uk-section-default">
          <div id="cursos-section-piloto-privado" className="uk-section uk-light uk-background-cover">
            <div className="uk-container">
              <div uk-grid="true">
                <div className="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s" />
                <div className="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s">
                  <div className="uk-card uk-padding">
                    <h2>Curso de Piloto Privado</h2>
                    <p>Curso de iniciación en la carrera de Piloto Civil.</p>
                    <p>Al obtener la licencia podrá desarrollar actividades aéreas y recreativas no remuneradas de acuerdo a la Reglamentación Aeronáutica Boliviana (RAB).</p>
                    <p>La duraci&oacute;n aproximada del curso es de 7 meses con la opción de continuar a la carrera de Piloto Comercial.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section-default">
          <div id="cursos-section-piloto-comercial" className="uk-section uk-light uk-background-cover">
            <div className="uk-container">
              <div uk-grid="true">
                <div className="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s">
                  <div className="uk-card uk-padding">
                    <h2>Curso de piloto comercial - IFR</h2>
                    <p>Este curso permite capacitar al alumno piloto con las técnicas y procedimientos de vuelo necesarios que le permitan desempeñarse en forma segura como piloto comercial, según sea el caso y conforme a los niveles de pericia que establece para cada caso la Reglamentación Aeronáutica Boliviana (RAB). Esta licencia le permitirá realizar vuelos remunerados.</p>
                    <p>La duraci&oacute;n del curso es de 1 a&ntilde;o.</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

      <div className="uk-section-default">
          <div id="cursos-section-competencia-linguistica" className="uk-section uk-light uk-background-cover">
            <div className="uk-container">
              <div uk-grid="true">
                
                <div className="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s">
                  <div className="uk-card uk-padding">
                    <h2>Evaluación de Competencia Linguística OACI</h2>
                    <p>La evaluación de Competencia Lingüística ha sido diseñada para medir la capacidad de hablar y la comprensión auditiva del evaluado.</p>
                    <p>El examen de Competencia Lingüística consta de 6 puntos de evaluación:</p>
                    <p>Pronunciación, Estructura, Vocabulario, Fluidez, Comprensión e Interacción.</p>
                    <p>Para estudiar de antemano puede referirse a los siguientes: <a href="https://www.ulc.gov.pl/_download/personel_lotniczy/ICAO_LPR/samples.html" target="_blank">Modelos de Evaluación</a>.</p>
                  </div>
                </div>
                <div className="uk-width-1-2@l uk-width-1-2@m uk-width-1-1@s" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
